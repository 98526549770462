import { Checkbox } from "antd";
import { notification } from "antd";
import React, { useState, useEffect } from "react";
import api from "../../../services/api";

function ExamSelector({
	onChangeSelectedExams,
	disabled = false,
	groups = null,
}) {
	const [examGroups, setExamGroups] = useState([]);
	const [selectedExams, setSelectedExams] = useState([]);

	const getExams = async () => {
		try {
			const { data } = await api.medicalExams.listSerialized();
			setExamGroups(data.groups);
		} catch (error) {
			notification.error({
				message: "Erro ao buscar exames.",
				description: error.message,
			});
		} 
	};

	const defineRowColor = (i) => {
		return i % 2 === 0 ? "white" : "#e6e6e6";
	};

	const handleOnChange = (e, array, variavel) => {
		const checked = e.target.checked;
		let formatedExams = [...selectedExams];
		let examCodes = [];

		switch (variavel) {
			case "group":
				examCodes = [];
				array.subgroups.forEach((subgroup) => {
					examCodes = examCodes.concat(subgroup.exams.map((exam) => exam.code));
				});
				break;
			case "subgroup":
				examCodes = array.exams.map((exam) => exam.code);
				break;
			case "exam":
				examCodes = array;
				break;
			default:
		}

		if (checked) {
			formatedExams = formatedExams.concat(examCodes);
		} else {
			formatedExams = formatedExams.filter((code) => !examCodes.includes(code));
		}

		setSelectedExams([...new Set(formatedExams)]);
	};

	const defineSelected = (array, variavel) => {
		let examCodes = [];
		switch (variavel) {
			case "group":
				examCodes = [];
				array.subgroups.forEach((subgroup) => {
					examCodes = examCodes.concat(subgroup.exams.map((exam) => exam.code));
				});
				break;
			case "subgroup":
				examCodes = array.exams.map((exam) => exam.code);
				break;
			default:
		}

		const notAll = examCodes.some((code) => !selectedExams.includes(code));

		return !notAll;
	};

	useEffect(() => {
		if (groups) {
			setExamGroups(groups);
		} else {
			getExams();
		}
	}, []);

	useEffect(() => {
		onChangeSelectedExams(selectedExams);
	}, [selectedExams]);

	return (
		<div
			style={{
				display: "flex",
				flexWrap: "wrap",
				columnGap: "50px",
				rowGap: "30px",
				marginLeft: "10px"
			}}
		>
			{examGroups.length !== 0 &&
				examGroups.map((group) => (
					<div key={group.description} style={{ width: '100%' }}>
						{!disabled ? (
							<Checkbox
								checked={defineSelected(group, "group")}
								onChange={(e) => handleOnChange(e, group, "group")}
							>
								<b>{group.description}</b>
							</Checkbox>
						) : (
							<b>{group.description}</b>
						)}

						<div
							className="subgroups"
							style={{
								display: "flex",
								flexWrap: "wrap",
								margin: "10px 0px",
								columnGap: "10px",
								rowGap: "10px",
							}}
						>
							{group.subgroups.map((subgroup) => (
								<div key={subgroup.description} style={{ minWidth: "250px" }}>
									<div>
										{subgroup.exams.map((exam, i) => (
											<div
												key={exam.code}
												style={{
													backgroundColor: defineRowColor(i),
													margin: "0px",
													padding: "2px 5px",
												}}
											>
												{!disabled ? (
													<Checkbox
														checked={selectedExams.includes(exam.code)}
														onChange={(e) =>
															handleOnChange(e, [exam.code], "exam")
														}
													>
														{exam.description}
													</Checkbox>
												) : (
													exam.description
												)}
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				))}
		</div>
	);
}

export default ExamSelector;
